import { BASE_URL, G_Value } from "../../global";
import axiosWithToken from "./axiosTokenConfig";


let cache = {};

const convertDegreeCandOhm = async (val, from, to, row) => {
    try{
        let referencedData = null;
        // get referernced data from database
        if(cache["unitConvDegreeCOhm"]?.[val]){
            referencedData = cache["unitConvDegreeCOhm"]?.[val];
        } else{
            const url = `${BASE_URL}dynamic`;
            const query = `SELECT CAST((SELECT ohm FROM unitConvDegreeCOhm WHERE ohm <= '${val}' ORDER BY ohm DESC LIMIT 1) AS DECIMAL(10,2)) AS lower_Value, CAST((SELECT MIN(ohm) FROM unitConvDegreeCOhm WHERE ohm > '${val}') AS DECIMAL(10,2)) AS higher_Value, (SELECT degreeC FROM unitConvDegreeCOhm WHERE ohm = (SELECT ohm FROM unitConvDegreeCOhm WHERE ohm <= '${val}' ORDER BY ohm DESC LIMIT 1)) AS lower_degreeC, (SELECT degreeC FROM unitConvDegreeCOhm WHERE ohm = (SELECT MIN(ohm) FROM unitConvDegreeCOhm WHERE ohm > '${val}')) AS higher_degreeC`;
            referencedData = await axiosWithToken.post(url, { query });
            referencedData = referencedData?.data?.[0];
            cache["unitConvDegreeCOhm"] = cache["unitConvDegreeCOhm"] || {};
            cache["unitConvDegreeCOhm"][val] = referencedData;
        }

         // Extract values, ensure they are parsed correctly
         const { lower_Value, higher_Value, lower_degreeC, higher_degreeC } = referencedData;
         // Convert string values to numbers
         const lowerValueNum = Number(lower_Value);
         const higherValueNum = Number(higher_Value);
         const lowerDegreeCNum = Number(lower_degreeC);
         const higherDegreeCNum = Number(higher_degreeC);

        const calculateValue =
        ((val - lowerValueNum) / (higherValueNum - lowerValueNum)) *
        (higherDegreeCNum - lowerDegreeCNum) +
        lowerDegreeCNum;

        if (calculateValue) {
        return Number(calculateValue.toFixed(3));
        } else {
        return val;
        }
    }catch(e){
        console.log("Error in customUnitConversion", e);
        return val;
    }
}

const convertDegreeCandMV = async (val, from, to, row, referenceData) => {
  try {
    let referencedData = null;
    if (cache["unitConvMvDegreeC"]?.[val]) {
      referencedData = cache["unitConvMvDegreeC"]?.[val];
    } else {
      let sensorType = referenceData?.materialType ? referenceData?.materialType : referenceData?.srfInstruments?.sensorType;
      const url = `${BASE_URL}dynamic`;
      let query;
      if (val > 0){
        // positive no search
          query = `SELECT *, CASE WHEN ABS(c0 - ${val}) <= 0.03 THEN 'c0' WHEN ABS(c1 - ${val}) <= 0.03 THEN 'c1' WHEN ABS(c2 - ${val}) <= 0.03 THEN 'c2' WHEN ABS(c3 - ${val}) <= 0.03 THEN 'c3' WHEN ABS(c4 - ${val}) <= 0.03 THEN 'c4' WHEN ABS(c5 - ${val}) <= 0.03 THEN 'c5' WHEN ABS(c6 - ${val}) <= 0.03 THEN 'c6' WHEN ABS(c7 - ${val}) <= 0.03 THEN 'c7' WHEN ABS(c8 - ${val}) <= 0.03 THEN 'c8' WHEN ABS(c9 - ${val}) <= 0.03 THEN 'c9' ELSE NULL END AS match_value, CASE WHEN ABS(c0 - ${val}) <= 0.03 THEN 'c1' WHEN ABS(c1 - ${val}) <= 0.03 THEN 'c2' WHEN ABS(c2 - ${val}) <= 0.03 THEN 'c3' WHEN ABS(c3 - ${val}) <= 0.03 THEN 'c4' WHEN ABS(c4 - ${val}) <= 0.03 THEN 'c5' WHEN ABS(c5 - ${val}) <= 0.03 THEN 'c6' WHEN ABS(c6 - ${val}) <= 0.03 THEN 'c7' WHEN ABS(c7 - ${val}) <= 0.03 THEN 'c8' WHEN ABS(c8 - ${val}) <= 0.03 THEN 'c9' ELSE 'c10' END AS upper_value FROM unitConvMvDegreeC WHERE type = '${sensorType}' AND (C0 IS NOT NULL  AND C1 IS NOT NULL  AND C2 IS NOT NULL  AND C3 IS NOT NULL  AND C4 IS NOT NULL AND C5 IS NOT NULL AND C6 IS NOT NULL AND C7 IS NOT NULL AND C8 IS NOT NULL AND C9 IS NOT NULL) AND ( (ABS(c0 - ${val}) <= 0.03) OR (ABS(c1 - ${val}) <= 0.03) OR (ABS(c2 - ${val}) <= 0.03) OR (ABS(c3 - ${val}) <= 0.03) OR (ABS(c4 - ${val}) <= 0.03) OR (ABS(c5 - ${val}) <= 0.03) OR (ABS(c6 - ${val}) <= 0.03) OR (ABS(c7 - ${val}) <= 0.03) OR (ABS(c8 - ${val}) <= 0.03) OR (ABS(c9 - ${val}) <= 0.03) ) order by id  asc limit 1 `;

      } else {
        // negative no search
        query = `SELECT *, CASE WHEN ABS(c1 - ${val}) <= 0.03 THEN 'c1' WHEN ABS(c2 - ${val}) <= 0.03 THEN 'c2' WHEN ABS(c3 - ${val}) <= 0.03 THEN 'c3' WHEN ABS(c4 - ${val}) <= 0.03 THEN 'c4' WHEN ABS(c5 - ${val}) <= 0.03 THEN 'c5' WHEN ABS(c6 - ${val}) <= 0.03 THEN 'c6' WHEN ABS(c7 - ${val}) <= 0.03 THEN 'c7' WHEN ABS(c8 - ${val}) <= 0.03 THEN 'c8' WHEN ABS(c9 - ${val}) <= 0.03 THEN 'c9' WHEN ABS(c10 - ${val}) <= 0.03 THEN 'c10' ELSE NULL END AS match_value, CASE WHEN ABS(c0 - ${val}) <= 0.03 THEN 'c10' WHEN ABS(c1 - ${val}) <= 0.03 THEN 'c0' WHEN ABS(c2 - ${val}) <= 0.03 THEN 'c1' WHEN ABS(c3 - ${val}) <= 0.03 THEN 'c2' WHEN ABS(c4 - ${val}) <= 0.03 THEN 'c3' WHEN ABS(c5 - ${val}) <= 0.03 THEN 'c4' WHEN ABS(c6 - ${val}) <= 0.03 THEN 'c5' WHEN ABS(c7 - ${val}) <= 0.03 THEN 'c6' WHEN ABS(c8 - ${val}) <= 0.03 THEN 'c7' WHEN ABS(c9 - ${val}) <= 0.03 THEN 'c8' WHEN ABS(c10 - ${val}) <= 0.03 THEN 'c9' ELSE NULL END AS upper_value FROM unitConvMvDegreeC WHERE type = '${sensorType}' AND (C0 IS NOT NULL AND C1 IS NOT NULL AND C2 IS NOT NULL AND C3 IS NOT NULL AND C4 IS NOT NULL AND C5 IS NOT NULL AND C6 IS NOT NULL AND C7 IS NOT NULL AND C8 IS NOT NULL AND C9 IS NOT NULL) AND (ABS(c0 - ${val}) <= 0.03 OR ABS(c1 - ${val}) <= 0.03 OR ABS(c2 - ${val}) <= 0.03 OR ABS(c3 - ${val}) <= 0.03 OR ABS(c4 - ${val}) <= 0.03 OR ABS(c5 - ${val}) <= 0.03 OR ABS(c6 - ${val}) <= 0.03 OR ABS(c7 - ${val}) <= 0.03 OR ABS(c8 - ${val}) <= 0.03 OR ABS(c9 - ${val}) <= 0.03 OR ABS(c10 - ${val}) <= 0.03) ORDER BY id DESC LIMIT 1`;
      }

      referencedData = await axiosWithToken.post(url, { query });
      referencedData = referencedData?.data?.[0];
      cache["unitConvMvDegreeC"] = cache["unitConvMvDegreeC"] || {};
      cache["unitConvMvDegreeC"][val] = referencedData;
    }
    //   if (referencedData) {
    const { match_value, upper_value, degreeC, ...coefficients } =
      referencedData;
    const lower_Value = coefficients[match_value];
    const upper_Value = coefficients[upper_value];
    const lower_temp =
      parseFloat(degreeC) + parseInt(match_value.replace("c", ""));
    const upper_temp =
      parseFloat(degreeC) + parseInt(upper_value.replace("c", ""));

    let calculateValue =
      ((val - lower_Value) / (upper_Value - lower_Value)) *
        (upper_temp - lower_temp) +
      lower_temp;

    if (calculateValue) {
      return Number(calculateValue.toFixed(3));
    } else {
      return val;
    }
  } catch (e) {
    console.log("Error in convertDegreeCandMV", e);
    return val;
  }
};

const convertGramAndNewton = (val, from, to, row) => {
  // if(from === "g") return val*(1000/9.80665);
  if (from === "g") return val * (G_Value / 1000);
  if (from === "N") return val * (9.80665 / 1000);
};

const convertNewttonAndShoreA = (val, from, to, row) => {
  if (to === "shore-A") return val / 0.075;
  if (to === "shore-D") return val / 0.4445;
};

const convertGramAndShoreDandShoreA = (val, from, to, row) => {
  if (to === "shore-D") return (val * (G_Value / 1000)) / 0.4445;
  if (to === "shore-A") return ((val * (G_Value / 1000)) / 0.075);
};

const convertMMandShoreDandShoreA = (val, from, to, row) => {
  return val / 0.025 - 100;
};

export const customUnitConvCombinations = [
    ["°C", "Ω"],
    ["Ω", "°C"],
    ["°C", "mV"],
    ["mV", "°C"],
    ["N", "g"],
    ["g", "N"],
    ["N", "shore-A"],
    ["N", "shore-D"],
    ["g", "shore-A"],
    ["g", "shore-D"],
    ["mm", "shore-A"],
    ["mm", "shore-D"],
    ["shore-A", "N"],
    ["shore-D", "N"],
    ["shore-A", "g"],
    ["shore-D", "g"],
    ["shore-A", "mm"],
    ["shore-D", "mm"],
];
export const containsCustomUnitPair = (inputPair) => {
    const inputSet = new Set(inputPair);
    return customUnitConvCombinations.some(pair => new Set(pair).size === inputSet.size && pair.every(unit => inputSet.has(unit)));
}

export const customUnitConversion = async (val, from, to, row = null, referenceData = {}) => {
    if(from === to) return val

    // 1. case 1: Ω to °C, and vice versa
    if(["Ω"].includes(from) && ["°C"].includes(to)){
        return await convertDegreeCandOhm(val, from, to, row)
    }

    // 2. case 2: mV to °C 
    if(["mV"].includes(from) && ["°C"].includes(to)){
        return await convertDegreeCandMV(val, from, to, row, referenceData)
    }

    // 3. Newton to gram and vice versa
    if (["N", "g"].includes(from) && ["N", "g"].includes(to)){
        return convertGramAndNewton(val, from, to, row);
    }

  // 4. Newton to shore-A,shore-D and vice versa
  if (["N"].includes(from) && ["shore-A", "shore-D"].includes(to)) {
    return convertNewttonAndShoreA(val, from, to, row);
  }

  // 5. Gram to shore-A,shore-D and vice versa
  if (["g"].includes(from) && ["shore-D", "shore-A"].includes(to)) {
    return convertGramAndShoreDandShoreA(val, from, to, row);
  }

  // 6. MM to shore-A, shore-D and vice versa
  if (["mm"].includes(from) && ["shore-D", "shore-A"].includes(to)) {
    return convertMMandShoreDandShoreA(val, from, to, row);
  }

    // clean cache after 3 minutes
    setTimeout(() => {
        cache = {};
    }, 180000);
}