import { BASE_URL } from '../../global';
import { toast } from 'react-toastify';
import { makeChunks } from '../helper/helpers';
import unitConvertor from '../../utils/components/unitConvertor';
import axiosWithToken from '../../utils/components/axiosTokenConfig';
import {
  containsCustomUnitPair,
  customUnitConversion,
} from '../../utils/components/customUnitConvertor';

const tablesData = {};

const TABLES_MAP = {
  datasheet: 'datasheets',
  instrument: 'instruments',
  datasheeetStaticTable: 'datasheetStaticTables',
  datasheetStaticReading: 'datasheetStaticReadings',
  standard: 'standards',
  standardRange: 'standardRanges',
  supportiveInstrumentRange: 'instruments',
  srfInstrument: 'srfInstruments',
  settings: 'settings',
  // datasheetTemplate,
  // cmcs,
};

function findTokens(str) {
  const regex = /\$[\w.:' ]+/g;
  let matches = str?.match(regex);

  if (matches) {
    return matches;
  } else {
    return [];
  }
}

const fetchTables = async (queries) => {
  let chunks = makeChunks(Object.entries(queries));
  for (let chunk of chunks) {
    await Promise.all(
      chunk.map((e) => axiosWithToken.get(e[1]).then((res) => ({ name: e[0], value: res.data[0] })))
    )
      .then((res) => {
        res.forEach((e) => (tablesData[e.name] = e.value));
      })
      .catch((err) => {
        console.error('err : ', err);
      });
  }
};

const parseTokens = (tokens, attributeTables, tables, idMaps) => {
  let tp = [];

  tokens = tokens
    .map((token) => {
      // trim unwanted spaces
      token = token.trim();

      // remove additional attributes from token '.unit', '.all', '.higher'
      if (token.includes('.unit')) {
        token = token.replaceAll('.unit', '');
      }
      if (token.includes('.all')) {
        token = token.replaceAll('.all', '');
      }
      if (token.includes('.higher')) {
        token = token.replaceAll('.higher', '');
      }

      tp = token.substring(1).split('.');
      tp[2] = tp[2]?.split(':');
      return tp;
    })
    .map((e, i) => {
      if (!TABLES_MAP[e[0]]) {
        toast.warning('found wrong table in equation : ' + tokens[i]);
        return null;
      }
      if (e[2]) {
        attributeTables[
          `${BASE_URL}${TABLES_MAP[e[0]]}?_where=(${e[2][0]},eq,${e[2][1]?.replaceAll(
            "'",
            ''
          )})&_fields=${e[1]}`
        ] = [e[1]];
        return null;
      } else if (idMaps[e[0]]) {
        let id = idMaps[e[0]];
        let tId = `${BASE_URL}${TABLES_MAP[e[0]]}_${id}`;
        if (tables[tId]) tables[tId][0][e[1]] = true;
        else tables[tId] = [{ [e[1]]: true }, `${e[0]}_${id}`, id];
        return null;
      }
      return e;
    })
    .filter((e) => e);
  return tokens;
};
export const generateTableData = async (
  unceratintyFactors,
  readings,
  tableId,
  datasheetId,
  instrumentId,
  standardToUncertaintyFactorMap
) => {
  let conditions = unceratintyFactors.map((e) => [e.id, JSON.parse(e.showcondition)]);
  let configs = unceratintyFactors.map((e) => [e.id, JSON.parse(e.sourceconfig)]);

  let sensitiveCoefficientFormulas = unceratintyFactors.map((e) => [
    e.id,
    JSON.parse(e.sensitives),
  ]);

  let valueAdjustmentFormulas = unceratintyFactors.map((e) => [
    e.id,
    JSON.parse(e.valueAdjustment),
  ]);

  let standardRanges = readings.map((reading) => [
    ...reading.standardRanges,
    reading.supportiveRanges,
  ]);

  let readingIds = readings.map((reading) => reading.id);
  let tokensDict = {};
  let tables = {};
  let attributeTables = {};
  let tablesIdMap = {
    settings: null,
    datasheet: datasheetId,
    srfInstrument: datasheetId,
    instrument: instrumentId,
    datasheeetStaticTable: tableId,
    readings: readingIds.map((readingId, i) => ({
      datasheetStaticReading: readingId,
      masters: [
        ...standardRanges[i].map((std) => ({
          standard: std[0],
          standardRange: std[1],
        })),
      ],
    })),
  };

  let tokens = [];

  conditions.forEach((condition, i) => {
    tokens = [
      ...findTokens(Object.entries(condition[1]).flat().join(',')),
      ...findTokens(Object.entries(configs[i][1]).flat().join(',')),
      ...findTokens(Object.entries(sensitiveCoefficientFormulas[i][1]).flat().join(',')),
      ...findTokens(
        Object.entries(valueAdjustmentFormulas[i][1] || {})
          .flat()
          .join(',')
      ),
    ];
    tokens = parseTokens(tokens, attributeTables, tables, tablesIdMap);

    if (tokens?.length > 0) tokensDict[condition[0]] = tokens;
  });

  let additionalTokens = [];
  unceratintyFactors.forEach((uncertaintyFactor) => {
    if (uncertaintyFactor.additionalReferences) {
      additionalTokens = [...additionalTokens, ...uncertaintyFactor.additionalReferences];
    }
    tokens = parseTokens(additionalTokens, attributeTables, tables, tablesIdMap);

    tokensDict[0] = [...(tokensDict[0] || []), ...tokens];
  });

  //  query generator
  let query, id;
  Object.keys(tokensDict).forEach((ufId) => {
    tokensDict[ufId].forEach((token) => {
      readingIds.forEach((_, i) => {
        query = null;
        id = null;
        if (token[0]?.includes('standard')) {
          // process tokens associated with standards or standard ranges
          for (let j = 0; j < standardRanges[i].length; j++) {
            let stdRange = standardRanges[i][j];
            if (standardToUncertaintyFactorMap[stdRange[0]]?.includes(ufId) || ufId == 0) {
              id = tablesIdMap.readings[i].masters[j][token[0]];
              if (id) {
                query = `${BASE_URL}${TABLES_MAP[token[0]]}_${id}`;
                if (tables[query]) {
                  tables[query][0][token[1]] = true;
                } else {
                  tables[query] = [{ [token[1]]: true }, `${token[0]}_${id}`, id];
                }
                if (token[0] == 'standardRange') {
                  if (!tables[query][0]['rangeName']) {
                    tables[query][0]['rangeName'] = true;
                  }
                }
                if (token[0] == 'standard') {
                  tables[query][0]['type'] = true;
                }
              }
            }
          }
        } else {
          // process tokens assocated with datasheet reading
          id = tablesIdMap.readings[i][token[0]];
          if (id) {
            query = `${BASE_URL}${TABLES_MAP[token[0]]}_${id}`;
            if (tables[query]) {
              tables[query][0][token[1]] = true;
            } else {
              tables[query] = [{ [token[1]]: true }, `${token[0]}_${id}`, id];
            }
          }
        }
      });
    });
  });

  let queries = {};
  Object.entries(attributeTables).forEach((e) => (queries[e[1]] = e[0]));
  Object.entries(tables).forEach((e) => {
    queries[e[1][1]] = `${e[0].split('_')[0]}?_fields=${Object.keys(e[1][0]).join(
      ','
    )}&_where=(id,eq,${e[1][2]})`;
  });
  await fetchTables(queries);
};

export const convertUnit = (val, from, to, baseValue = null) => {
  let res = val;
  val = val || 0;

  if (from && to) {
    if (from.trim() === 'ppm') {
      res = (val / 1000000) * (baseValue || 1);
    } else if (to.trim() === 'ppm') {
      res = val * 1000000 * (baseValue || 1);
    } else if (from.trim() === '%' && baseValue != null) {
      res = (Number(val) * Number(baseValue)) / 100;
    } else if (to.trim() === '%' && baseValue != null) {
      res = (Number(val) * 100) / Number(baseValue);
    } else {
      res = unitConvertor(val, from, to);
    }
  }

  return res;
};

export const convertUnitWrap = (val, from, to) => {
  return Number(convertUnit(Number(val), from, to));
};

export const resolveToken = (token, selectors) => {
  let value = null;
  token = token.trim();
  let tp = token.substring(1).split('.');
  tp[2] = tp[2]?.split(':');
  if (tp[2]?.[0] == 'unit') {
    token = `${tp[0]}_${selectors[tp[0]]}`;
    value = tablesData[token]?.[tp[1]]?.split('#')[1];
  } else if (tp[2]?.[0] == 'all') {
    value = [];
    let tokens = Object.keys(tablesData).filter((e) => {
      if (e.startsWith(`${tp[0]}_`)) {
        return e;
      }
    });
    tokens.forEach((e) => {
      if (tablesData[e]?.[tp[1]]) {
        // TODO: added static logic to skip supportive instrument, plese write generic logic irrespective column or table
        if (tp[0] == 'standard') {
          if (tablesData[e]?.type == 1) {
            value.push(tablesData[e]?.[tp[1]]);
          }
        } else {
          value.push(tablesData[e]?.[tp[1]]);
        }
      }
    });
    value = value.join(',');
  } else if (tp[2]?.[0] == 'higher') {
    token = `${tp[0]}_${selectors[tp[0]]}`;
    value = tablesData[token]?.[tp[1]];
    value = value?.split('|');
    value = value.map((e) => {
      return e?.split('#')?.[0];
    });
    value = String(Math.max(...value));
  } else if (!tp[2]) {
    token = `${tp[0]}_${selectors[tp[0]]}`;
    value = tablesData[token]?.[tp[1]];
  }
  return value;
};

export const resolveCondition = (conditions, selectors) => {
  let trueCondition = 'default';
  for (const element of conditions) {
    let condition = element;
    if (condition == 'default') continue;
    let updatedCondition = condition;
    let tokens = findTokens(condition).filter((token) => token != 'default');
    for (const element of tokens) {
      let token = element;
      let tokenResult = resolveToken(token, selectors);
      if (/\D/.test(tokenResult) || tokenResult == '') tokenResult = `'${tokenResult}'`;
      updatedCondition = updatedCondition.replace(token, tokenResult);
    }

    // eslint-disable-next-line no-new-func
    let res = Function('return ' + updatedCondition)();
    if (res === true) {
      trueCondition = condition;
      break;
    }
  }
  return trueCondition;
};

export const resolveFormula = (
  sourceFormula,
  updatedFormula,
  fixedUnit,
  selectors,
  referenceData,
  fallbackUnit,
  rangeCol
) => {
  let resolvedFormula = '';
  if (updatedFormula !== '') {
    let tokens = findTokens(sourceFormula);

    // 1. iterate tokens and resolve values, units
    let TokenValues = {};
    for (let i = 0; i < tokens?.length; i++) {
      let rawValue = resolveToken(tokens[i], selectors);
      rawValue = rawValue == null || rawValue == '' ? 'NA' : rawValue;
      if (rawValue.includes('|')) {
        // process for multiple values
        let values = rawValue?.split('|');
        let unit = values[0]?.split('#')[1] || '';
        let value = values.map((e) => e?.split('#')[0]).join(',');
        TokenValues[tokens[i]] = {
          value: value,
          unit: unit,
        };
      } else {
        // process for single value
        let value = rawValue?.split('#')[0] || 0;
        let unit = rawValue?.split('#')[1] || '';
        TokenValues[tokens[i]] = {
          value: value,
          unit: unit,
        };
      }
    }

    // 2. find desired unit for final uncertainty factor value
    // process: if tokenValues containes different unit types then consider fallbackUnit as desired unit, else consider unit from tokenValues
    let desiredUnit = fixedUnit;
    if (!desiredUnit) {
      let units = Object.values(TokenValues).map((e) => e.unit);
      units = units.filter((e) => e !== '');
      units = [...new Set(units)];
      desiredUnit = units?.length > 1 ? fallbackUnit : units[0];
    }

    // 3. replace token with respective values
    for (let i = 0; i < tokens?.length; i++) {
      let value = TokenValues[tokens[i]]?.value || 0;
      let unit = TokenValues[tokens[i]]?.unit || '';

      if (unit && fallbackUnit && unit !== desiredUnit) {
        // preapre base value: 1st column of reading table (reading range)
        let baseValue = referenceData?.readingRow?.[rangeCol]?.[0];

        if (unit?.toLowerCase().includes('fsd')) {
          let rangeValue = resolveToken('$standard.masterrange', selectors);
          rangeValue = rangeValue?.split('|')?.[1];
          rangeValue = rangeValue?.split('#')?.[0];

          if (isNaN(rangeValue)) {
            rangeValue = resolveToken('$standardRange.rangeName', selectors);
            rangeValue = rangeValue?.split('|')?.[1];
            rangeValue = rangeValue?.split('#')?.[0];
          }
          baseValue = Number(rangeValue);
          baseValue = baseValue;
          unit = '%';
        }
        value = convertUnit(value, unit, desiredUnit, baseValue);
      }

      updatedFormula = updatedFormula?.replace(tokens[i], value);
    }
    // 4. evaluate formula
    // eslint-disable-next-line no-new-func
    let resultValue = '';
    try {
      updatedFormula = updatedFormula?.replaceAll('$', '');
      resolvedFormula = updatedFormula;
      resultValue = Function('return ' + updatedFormula)();
    } catch (e) {
      resultValue = '[wrong formula] ' + updatedFormula;
    }

    resultValue = String(resultValue) + '#' + (desiredUnit || '');

    return [resultValue, resolvedFormula];
  } else return ['0', ''];
};

export const resolveUncertaintyValue = (
  unceratintyFactor,
  selectors,
  referenceData,
  fallbackUnit,
  rangeCol
) => {
  let dataSourceDetails = {};

  // 1. resolve condition and process source config
  // find values from table references
  let config = JSON.parse(unceratintyFactor.sourceconfig);
  let sourceValueUnit = unceratintyFactor.sourceValueUnit;
  let selectedCondition = resolveCondition(Object.keys(config), selectors);
  let sourceFormula = config[selectedCondition];
  let updatedFormula = sourceFormula;
  let res = resolveFormula(
    sourceFormula,
    updatedFormula,
    sourceValueUnit,
    selectors,
    referenceData,
    fallbackUnit,
    rangeCol
  );
  let sourceConfigRes = res[0];
  let resolvedFormula = res[1];

  dataSourceDetails = {
    ...dataSourceDetails,
    formula: updatedFormula,
    resolvedFormula: resolvedFormula,
  };

  // 2. resolve and process sensitive coefficient
  // find values from table references
  let sensitiveCoefficient = JSON.parse(unceratintyFactor.sensitives);
  let sensitiveCoefficientUnit = unceratintyFactor.sensitiveCoefficientUnit;
  selectedCondition = resolveCondition(Object.keys(sensitiveCoefficient), selectors);
  sourceFormula = sensitiveCoefficient[selectedCondition];
  res = resolveFormula(
    sourceFormula,
    sourceFormula,
    sensitiveCoefficientUnit,
    selectors,
    fallbackUnit
  );
  let sensitiveCoefficientRes = res[0];

  dataSourceDetails = {
    ...dataSourceDetails,
    sensitiveCoefficientFormula: sourceFormula,
  };

  // 2. resolve and process valueAdjustment
  // find values from table references
  let valueAdjustmentRes = '0';
  let valueAdjustment = JSON.parse(unceratintyFactor.valueAdjustment);
  if (valueAdjustment) {
    let valueAdjustmentUnit = unceratintyFactor.valueAdjustmentUnit;
    selectedCondition = resolveCondition(Object.keys(valueAdjustment), selectors);

    valueAdjustmentRes = valueAdjustment[selectedCondition];
    resolvedFormula = valueAdjustment[selectedCondition];
  }

  dataSourceDetails = {
    ...dataSourceDetails,
    valueAdjustmentFormula: sourceFormula,
  };

  return [
    sourceConfigRes,
    sensitiveCoefficientRes || 1,
    valueAdjustmentRes || 0,
    dataSourceDetails,
  ];
};

// TODO: simplify this function
const evaluate = (uncertaintyContribution, operator, valueAdjustmentValue) => {
  valueAdjustmentValue = Number(valueAdjustmentValue) || 0;
  if (operator === '-') uncertaintyContribution = uncertaintyContribution - valueAdjustmentValue;
  if (operator === '+') uncertaintyContribution = uncertaintyContribution + valueAdjustmentValue;
  if (operator === '*') uncertaintyContribution = uncertaintyContribution * valueAdjustmentValue;
  if (operator === '/') uncertaintyContribution = uncertaintyContribution / valueAdjustmentValue;
  if (operator === '%') uncertaintyContribution = uncertaintyContribution % valueAdjustmentValue;

  return uncertaintyContribution;
};

export const calculateUncertaintyContribution = async (
  ufLimitXi,
  probabiltyDistribution,
  sensitiveCoefficient,
  valueAdjustment,
  desiredUnit,
  baseValues
) => {
  // 1.0 prepare values
  let [ufLimitXiValue, ufLimitXiUnit] = ufLimitXi?.split('#') || [0, ''];
  // 1.1 if value in NaN then return as it is
  if (isNaN(ufLimitXiValue)) return ufLimitXi;

  let [sensitiveCoefficientValue, sensitiveCoefficientUnit] = String(sensitiveCoefficient)?.split(
    '#'
  ) || [0, ''];

  let operator = valueAdjustment[0];
  let valueAdjustmentValue = valueAdjustment.slice(1);

  // 2. calculate uncertaintyContribution
  let uncertaintyContribution = Number(
    (ufLimitXiValue / probabiltyDistribution) * sensitiveCoefficientValue
  );

  // 2.1 add value adjustment
  uncertaintyContribution = evaluate(uncertaintyContribution, operator, valueAdjustmentValue);

  // 3. convert value to desired unit
  let fromUnit =
    sensitiveCoefficientUnit && sensitiveCoefficientUnit !== ''
      ? sensitiveCoefficientUnit
      : ufLimitXiUnit;
  let baseValue = baseValues['percentBaseValue'];

  // 3.1 handle custom unit conversion cases
  if (fromUnit.toLowerCase() === '%fsd') {
    baseValue = baseValues['factorFSDBaseValue'];
    fromUnit = '%';
  }

  // 3.2 handle standard unit conversion cases
  if (containsCustomUnitPair([fromUnit, desiredUnit])) {
    uncertaintyContribution = await customUnitConversion(
      Number(uncertaintyContribution),
      fromUnit,
      desiredUnit,
      null, //current row
      null // base value
    );
  } else {
    uncertaintyContribution = convertUnit(
      uncertaintyContribution,
      fromUnit,
      desiredUnit,
      Number(baseValue)
    );
  }
  return String(uncertaintyContribution + `#${desiredUnit}`);
};
