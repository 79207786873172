import moment from "moment";

// props : {
//  branch
//  discipline,
//  calibrationProcedureNo
// }
export const generateNumber = (format = "", uid, props) => {
  let isentryDate =  props?.entryDate  ? new Date(props?.entryDate) : new Date();
  if (format.includes("DDMMYYYY")) {
    format = format.replace("DDMMYYYY", moment(isentryDate).format("DDMMYYYY"));
  }
  if (format.includes("MM/YYYY")) {
    format = format.replace("MM/YYYY", moment(isentryDate).format("MM/YYYY"));
  }
  if (format.includes("DDMMYY")) {
    format = format.replace("DDMMYY", moment(isentryDate).format("DDMMYY"));
  }
  if (format.includes("YYYYMMDD")) {
    format = format.replace("YYYYMMDD", moment(isentryDate).format("YYYYMMDD"));
  }
  if (format.includes("YYYY/MM/DD")) {
    format = format.replace("YYYY/MM/DD", moment(isentryDate).format("YYYY/MM/DD"));
  }
  if (format.includes("YYMMDD")) {
    format = format.replace("YYMMDD", moment(isentryDate).format("YYMMDD"));
  } else if (format.includes("MMYYYY")) {
    format = format.replace("MMYYYY", moment(isentryDate).format("MMYYYY"));
  } else if (format.includes("DD/MM/YYYY")) {
    format = format.replace(
      "DD/MM/YYYY",
      moment(isentryDate).format("DD/MM/YYYY")
    );
  } else if (format.includes("DD/MM/YY")) {
    format = format.replace("DD/MM/YY", moment(isentryDate).format("DD/MM/YY"));
  } else if (format.includes("YYYY-DD-MM")) {
    format = format.replace(
      "YYYY-DD-MM",
      moment(isentryDate).format("YYYY-DD-MM")
    );
  } else if (format.includes("YYMM")) {
    format = format.replace("YYMM", moment(isentryDate).format("YYMM"));
  }
    else if (format.includes("DD-MM-YY")) {
    format = format.replace("DD-MM-YY", moment(isentryDate).format("DD-MM-YY"));
  }
  else if (format.includes("MM-YY")) {
    format = format.replace("MM-YY", moment(isentryDate).format("MM-YY"));
  }
  else if (format.includes("YYYY/MM")) {
    format = format.replace("YYYY/MM", moment(isentryDate).format("YYYY/MM"));
  } else if (format.includes("YYYY")) {
    format = format.replace("YYYY", moment(isentryDate).format("YYYY"));
  } else if (format.includes("DDYY")) {
    format = format.replace("DDYY", moment(isentryDate).format("DDYY"));
  } else if (format.includes("DDMM")) {
    format = format.replace("DDMM", moment(isentryDate).format("DDMM"));
  } else if (format.includes("MMYY")) {
    format = format.replace("MMYY", moment(isentryDate).format("MMYY"));
  } else if (format.includes("MM/YY")) {
    format = format.replace("MM/YY", moment(isentryDate).format("MM/YY"));
  } else if (format.includes("M/YY")) {
    format = format.replace("M/YY", moment(isentryDate).format("M/YY"));
  } 

  let no = format.indexOf("{no}");
  let digits = 0;
  if (no !== -1 && /[\d]/.test(format.slice(no + 4))) {
    digits = Number(
      format
        .slice(no + 4)
        ?.split("]")[0]
        ?.split("[")?.[1]
    );
    format = format.replace(`[${digits}]`, "");
  }
  format = format.replace(
    "{no}",
    digits ? `${uid}`.padStart(digits, "0")+"F" : uid
  );
  no = format.indexOf("{no}");
  format = format.replace("{branch}", props?.branch);
  format = format.replace("{discipline}", props?.discipline);
  format = format.replace(
    "{calibrationProcedureNo}",
    props?.calibrationProcedureNo
  );
  return format;
};

export const getFormattedDates = (from, to) => {
  const fromDate = moment(from).startOf('day').format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const toDate = moment(to).endOf('day').format("YYYY-MM-DDTHH:mm:ss.SSSZ");

  return { fromDate, toDate };
};


export const indianStates = [
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttarakhand',
  'West Bengal',
  'Andaman and Nicobar Islands',
  'Dadra and Nagar Haveli and Daman and Diu',
  'Jammu & Kashmir',
  'Lakshadweep',
  'Chandigarh',
  'Delhi',
  'Ladakh',
  'Puducherry'
];