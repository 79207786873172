var angle;

angle = {
  rad: {
    name: {
      singular: "radian",
      plural: "radians",
    },
    to_anchor: 180 / Math.PI,
  },
  grad: {
    name: {
      singular: "gradian",
      plural: "gradians",
    },
    to_anchor: 9 / 10,
  },
  "°": {
    name: {
      singular: "degree",
      plural: "degrees",
    },
    to_anchor: 1,
  },
  "'": {
    name: {
      singular: "arcminute",
      plural: "arcminutes",
    },
    to_anchor: 1 / 60,
  },
  '"': {
    name: {
      singular: "arcsecond",
      plural: "arcseconds",
    },
    to_anchor: 1 / 3600,
  },
};

module.exports = {
  metric: angle,
  _anchors: {
    metric: {
      unit: "deg",
      ratio: 1,
    },
  },
};
