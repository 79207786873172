import React, { useState, useEffect, Fragment } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { FormControlLabel, Typography, useMediaQuery } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import QRCode from "react-qr-code";
import { Autocomplete } from "@mui/material";
import { BASE_URL, DOMAIN } from "./../../global";
import { Grid, TextField, Toolbar } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToastContainer, toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from '@mui/material/CircularProgress';
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import "./certificateList.css";
import { certificateListGuide } from "./guide";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnitechHeaderNabl from "../image/sticker_nabl.jpeg";
import UnitechHeaderNonNabl from "../image/sticker_nonnabl.jpeg";
import UnitechHeaderIas from "../image/sticker_ias.jpeg";
import ViewUploadedTrainingFile from "./viewUploadedTrainingFile";
import { SopViewFile, TrainingAndMasterViewFile } from "./sopandtraingbtn";
import ListWrap from "../commonComponent/listWrap";
import MultipleCertificatePrintButton from "./multipleCertificatePrintButton";
import { getFormattedDates } from "../../utils/utils";
import MultipleCertificateCopyUrl from "./multipleCertificateCopyUrl";
import { mulipleApproveCertificates } from "./mulipleApproveCertificates";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");



export default function CertificateList(props) {
  const certificateListGuides = certificateListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const printComponentRef = React.useRef();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [certificate, setCertificate] = useState([]);
  const [userList, setUserList] = useState([]);
  const [allcertificateIds, setAllCertificateIds] = useState([]);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = React.useState(null);
  const [qrModalOpen, setQrModalOpen] = React.useState(false);
  const [progressModalOpen, setProgressModalOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [enableDisable, setEnableData] = React.useState("");
  const [instrumentName, setInstrumentName] = React.useState("");
  const [calType, setCalType] = React.useState("");
  const [idNumber, setIdNumber] = React.useState("");
  const [calDate, setCalDate] = React.useState("");
  const [dueDate, setDueDate] = React.useState("");
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "DUC ID");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [totalRows, setTotalRows] = React.useState(0);
  const [location, setLocation] = React.useState("");
  const [certificateNumber, setCertificateNumber] = React.useState("");
  const [calibratedby, setCalibratedby] = React.useState("");
  const [certId, setCertId] = React.useState("");
  const _clientId = localStorage.getItem("clientId");
  const _companyHierarchy = localStorage.getItem("companyHierarchy");
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const [revisionNumber, setRevisionNumber] = React.useState("");
  const [userType, setUserType] = React.useState(localStorage.getItem("type"));
  const [userId, setUserId] = React.useState(localStorage.getItem("id"));
  const [branch, setBranch] = React.useState(localStorage.getItem("branch"));
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const validCompanies = localStorage.getItem("validClients");
  const [stikerLoader, setStikerLoader] = React.useState(false);
  const [settingList, setSettingList] = React.useState({});
  const [searched, setSearched] = React.useState(false);
  const [result, setResult] = React.useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const disciplineID = localStorage.getItem("disciplineID");
  const navigate = useNavigate();
  const [multiPrintRow, setmultiPrintRow] = useState(false);
  const [multiCopyURL, setmultiCopyURL] = useState(false);

  const [selectedSRFMultiple, setselectedSRfMultiple] =
  useState(null);
  const [multipleSRF, setmultipleSRF] = useState(false);

    const columns =[
      {
        id: "id",
        label: "Sr.No.",
        align: "left",
        minWidth: 40,
        padding : "5px"
      },
      {
        id: "customerName",
        label: "Client",
        align: "left",
        minWidth: 40,
      },
      {
        id: "serviceReqNumber",
        label: "Service Request Number",
        align: "left",
        minWidth: 40,
        // bgcolor:
      },
      {
        id: "certificateNumber",
        label: "Certificate Number",
        align: "left",
        minWidth: 40,
      },
      {
        id: "ULRNo",
        label: "ULR No",
        align: "left",
        minWidth: 40,
      },
      {
        id: "jobNumber",
        label: "Job Number",
        align: "left",
        minWidth: 40,
      },
      {
        id: "companyHierarchy",
        label: "Division",
        align: "left",
        minWidth: 40,
      },
      {
        id: "companyHierarchy",
        label: "Department",
        align: "left",
        minWidth: 40,
      },
      {
        id: "instrumentName",
        label: "Instrument Name",
        align: "left",
        minWidth: 40,
      },
      {
        id: "locationOfInstrument",
        label: "DUC Location",
        align: "left",
        minWidth: 40,
      },
      {
        id: "range",
        label: "Range",
        minWidth: 40,
        align: "left",
      },
      {
        id: "lc",
        label: "Least Count",
        align: "left",
        minWidth: 40,
      },
      {
        id: "DUCID",
        label: "DUC ID",
        minWidth: 40,
        align: "left",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "SrNumber",
        label: "Serial Number",
        minWidth: 40,
        align: "left",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "dateOfCalibration",
        label: "Date of Calibration",
        minWidth: 40,
        align: "left",
        format: (value) => value.toFixed(2),
      },
      {
        id: "dueDate",
        label: "Due Date",
        minWidth: 40,
        align: "left",
        format: (value) => value.toFixed(2),
      },
    ];

    if (userType !== "3") {
      columns.push({
        id: "calibratedby",
        label: "Calibrated By",
        minWidth: 40,
        align: "left",
        format: (value) => value.toFixed(2),
      });
    }

  const updateEnableDisable = (value, id) => {
    const newColumnName = [...enableDisable];
    newColumnName[id] = id + "," + value;
    setEnableData(newColumnName);
  };
  const handleOpen = () => setQrModalOpen(true);
  const handleClose = () => setQrModalOpen(false);
  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);
  const handleCloseProgress = () => setProgressModalOpen(false);
  const handleOpenProgress = (row) => {
    setSelectedRow(row);
    setProgressModalOpen(true);
  };

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const qrModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : 600,
    maxWidth: isMobile ? "90%" : "none",
    height: 300,
    bgcolor: "background.paper",
    border: "2px solid cadetblue",
    boxShadow: 24,
  };

  const getCal = (id) => {
    setStikerLoader(true);
    axiosWithToken
      .get(
        BASE_URL +
          `xjoin?_join=cer.certificates,_j,d.datasheets,_j,usr.users&_on1=(cer.id,eq,d.id)&_on2=(d.calibratedby,eq,usr.id)&_fields=cer.approvedby,d.calibratedby,d.revisionNumber,d.calibrationDate,usr.userName&_where=(cer.id,eq,${id})`
      )
      .then((res) => {
        setCalibratedby(res.data[0]?.usr_userName);
        setRevisionNumber(res.data[0]?.d_revisionNumber);
        setStikerLoader(false);
      }).catch((err) => {
        console.error("Error fetching data:", err);
        setStikerLoader(false);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getCal" ,"Certificate List")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        Client: "companyName",
        "Instrument Name": "instrumentName",
        "DUC ID": "DUCID",
        "Serial Number": "serialNumber",
        "SRF Number": "serviceReqNumber",
        "Job Number": "jobNumber",
        "Certificate Number": "certificateNumber",
        "DUC Location": "locationOfInstrument",
        "ULR No": "ULRNo",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      const { fromDate, toDate } = getFormattedDates(from, to);
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` calibrationDate between '${fromDate}' and '${toDate}'`;
    }
    if (whereConditions == "") {
      whereConditions += ` where status != -1`;
    } else {
      whereConditions += ` and status != -1 `;
    }
    if(props.isCount == true){
      whereConditions +=
      whereConditions.length > 0
        ? ` and calibratedby is NOT NULL and approvedby is NULL and discID IN (${disciplineID}) `
        : `where calibratedby is NOT NULL and approvedby is NULL and discID IN (${disciplineID}) `;
    }
    if (props.today) {
      const today = new Date().toISOString().slice(0, 10);
      whereConditions += (whereConditions ? " and " : " and ") + `DATE(lastmodified) = '${today}'`;
    }
    if (userType == "2" && props.rolesInfo?.currentRole?.id == 2 && disciplineID !="null") {
      whereConditions +=
        whereConditions.length > 0
          ? ` and (discID IN (${disciplineID}) `
          : `where (discID IN (${disciplineID}) `;
      whereConditions +=
        validCompanies?.length > 0
          ? whereConditions.length > 0
            ? ` or custid in (${validCompanies}))`
            : ` where custid in (${validCompanies})`
          : ")";
    }
    if (userType == "2" && disciplineID=="null") {
      whereConditions +=
        whereConditions.length > 0
          ? ` and (assignedUser=${userId}`
          : `where (assignedUser=${userId}`;
      whereConditions += ` or createdby=${userId}`;
      whereConditions +=
        validCompanies?.length > 0
          ? whereConditions.length > 0
            ? ` or custid in (${validCompanies}))`
            : ` where custid in (${validCompanies})`
          : ")";
    }
    if (userType === "3") {
      whereConditions += whereConditions.length > 0
        ? ` and (clientId=${_clientId}) and approvedby IS NOT NULL and approvedby != ''`
        : `where (clientId=${_clientId}) and approvedby IS NOT NULL and approvedby != ''`;
    }
    

    if (userType === "3" && _companyHierarchy && _companyHierarchy !== "null") {
      whereConditions += whereConditions.length
        ? ` and (companyHierarchy='${_companyHierarchy}')`
        : ` where (companyHierarchy='${_companyHierarchy}')`;
    }
    

    whereConditions +=
      userType == 4 && branch && branch.length
        ? (whereConditions ? " and " : "") + ` branch in (${branch})`
        : "";

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT * FROM certificatesView ${whereConditions} order by id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `select count(*) as no_of_rows FROM certificatesView ${whereConditions}`;

    return data;
  };

  // api calls
  function getTotalRows() {
    // var data = {
    //   query: userType == '2'
    //     ? `select count(*) as no_of_rows from certificates as cert left join datasheets as ds on ds.id=cert.id where ds.assignedUser=${userId} or cert.createdby=${userId}`
    //     : `select count(*) as no_of_rows from certificates`
    // }
    let data = getQuery(true);
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0].no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getTotalRows" ,"Certificate List")}><u>Error: {err.message}</u></h6>);
        }
      });
  }

  function fetchCertificates() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setCertificate([...res.data]);
        let data = res.data;
        const AllIds = data.map((e) => e.id);
        setAllCertificateIds(AllIds);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "fetchCertificates" ,"Certificate List")}><u>Error: {err.message}</u></h6>);
        }
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);    
    setSearched(true);
    fetchCertificates();
    getTotalRows();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched");
    sessionStorage.removeItem("currentPath");
    setSearchBy("DUC ID");
    setSearchKey("");
    setFrom(null);
    setTo(null);
    setSearched(false); 
    navigate("/certificate");
  };

  const processEnableDisable = (enableDisable) => {
    if (enableDisable.length > 0) {
      return enableDisable
        .map(function (item) {
          if (item) {
            let [id, closed] = item.split(",");
            return closed === "true" ? id : null;
          }
        })
        .filter(function (item) {
          return item !== undefined && item !== null;
        });
    }
    return [];
  };

  function fetchSettings() {
    axiosWithToken
      .get(BASE_URL + `settings?_where=(status,eq,1)`)
      .then((res) => {
         let settings = res.data;
        let _settingList = {};
        settings.forEach((setting) => {
          _settingList[setting.keyName] = setting.value;
        });
        setSettingList(_settingList);
      })
      .catch((err) => {
        console.error("Error fetching setting:", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "fetchSettings", "Certificate List")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }

  const getUsersList = () => {
    axiosWithToken
      .get(BASE_URL + `users?_where=(status,eq,1)`)
      .then((res) => {
        setUserList(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  useEffect(() => {
    setResult(processEnableDisable(enableDisable));
  }, [enableDisable]);

  useEffect(() => {
    fetchSettings();
    getUsersList();
  }, []);

  useEffect(() => { 
    getTotalRows();
    fetchCertificates();
  }, [rowsPerPage, page, disciplineID]);

  useEffect(() => {
    if (searched==false) {
      fetchCertificates();
      getTotalRows();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  useEffect(() => {
    if (props.isCount === true) {
      getTotalRows();
      fetchCertificates();
    }
  }, [props.isCount]);

  useEffect(() => {
    if (selectedSRFMultiple) {
      setSearchBy("SRF Number");
      setSearchKey(selectedSRFMultiple);
      setmultipleSRF(true);     
    }
  }, [selectedSRFMultiple]); 

  useEffect(() => {
    if (multipleSRF) {
      fetchCertificates();
      getTotalRows();
      setmultipleSRF(false); 
    }
  }, [multipleSRF,selectedSRFMultiple]);

  const renderProgressModal = () => {
    if (!selectedRow) return null;
    return (
      <Modal
        open={progressModalOpen}
        onClose={handleCloseProgress}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            height: "70%",
            backgroundColor: "white",
            padding: 3,
            borderRadius: 2,
          }}
        >
          <Box sx={{display:"flex", justifyContent:"space-between", mb:1}}>
          <Typography variant="h6">Progress Details</Typography>

          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={handleCloseProgress}
          >
            X
          </Button>
          </Box>

          {settingList["activeRole1"] != "false" && (
            <Typography
              sx={{ color: "black", backgroundColor: "#22c9b0", padding: 1 }}
              variant="body1"
            >
              Calibrated :{" "}
              {
                userList.find((user) => user.id == selectedRow.calibratedby)
                  ? `${userList.find((user) => user.id == selectedRow.calibratedby)?.userName} ${selectedRow?.calibrateddesignation ? `(${selectedRow?.calibrateddesignation})` : ''}`
                  : "Pending..."
              }
            </Typography>
          )}
          {settingList["activeRole2"] != "false" && (
            <Typography
              sx={{
                color: "black",
                backgroundColor: "rgb(160 160 247)",
                padding: 1,
              }}
              variant="body1"
            >
              Certificate Approval :{" "}
              {
                userList.find((user) => user.id == selectedRow.approvedby)
                  ? `${userList.find((user) => user.id == selectedRow.approvedby)?.userName} ${selectedRow?.approveddesignation ? `(${selectedRow?.approveddesignation})` : ''}`
                  : "Pending..."
              }
            </Typography>
          )}
          {settingList["activeRole3"] != "false" && (
            <Typography
              sx={{
                color: "black",
                backgroundColor: "rgb(243 146 243)",
                padding: 1,
              }}
              variant="body1"
            >
              Customer Approval (Reviewed) :{" "}
              {
                userList.find((user) => user.id == selectedRow.reviewedBy)
                  ?.userName || "Pending..."
              }              
            </Typography>
          )}
          {settingList["activeRole4"] != "false" && (
            <Typography
              sx={{
                color: "black",
                backgroundColor: "rgb(181 247 204)",
                padding: 1,
              }}
              variant="body1"
            >
              Customer Approval (Signed) :{" "}
              {
                userList.find((user) => user.id == selectedRow.signedBy)
                  ?.userName || "Pending..."
              }
            </Typography>
          )}
          {settingList["activeRole5"] != "false" && (
            <Typography
              sx={{ color: "black", backgroundColor: "#f59b79", padding: 1 }}
              variant="body1"
            >
              Customer Approval (Witnessed) :{" "}
              {
                userList.find((user) => user.id == selectedRow.witnessBy)
                  ?.userName || "Pending..."
              } 
            </Typography>
          )}
          <br />

          {settingList["SOPUpload"] === "true" && (
            <SopViewFile instrumentId={selectedRow.instrumentId} />
          )}
          {settingList["TrainingAndMasterCertificate"] === "true" && (
            <>
              <TrainingAndMasterViewFile
                calibratedbyID={selectedRow.calibratedby}
              />
              <ViewUploadedTrainingFile certificateId={selectedRow.id} />
            </>
          )}
        </Box>
      </Modal>
    );
  };

  const renderQrModal = () => {
    const fullHeader = true;
    const isHeaderAtLeft = true;
    return (
      <Modal
        open={qrModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={qrModalStyle} ref={printComponentRef}>
          {stikerLoader ? (
            <Box
              BackdropComponent={Backdrop}
              style={{
                width: "100%",
                height: "100%",
                bgcolor: "background.paper",
                border: "2px solid cadetblue",
                padding: "30px",
                textAlign: "center",
                display: "grid",
                placeItems: "center",
              }}
            >
              <CircularProgress color="success" />
            </Box>
          ) : (
            <Box>
              {/* <div className='qrModalStyle'> */}
                <img src={
        calType == 1
          ? UnitechHeaderNabl
          : calType == 2
          ? UnitechHeaderIas
          : UnitechHeaderNonNabl
      }
                  // src={Global}
                  style={{
                    width: "100%",
                    height: "86px",
                    objectFit: "contain",
                  }}
                  alt="rq-logo"
                ></img>
              

              <div style={{ flex: "1", display: "flex" }}>
                {/* <div style={{ width: "11%" }}>
              <img
                src={leftPanel}
                style={{ maxWidth: "100%", height: "100%" }}
                alt="QR-Header"
              ></img>
            </div> */}
                <div style={{ width: "90%", margin: "auto" }}>
                  <table
                    style={{
                      width: "100%",
                      height: "100%",
                      tableLayout: "fixed",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td className="stickerTd">
                          <b>CERTIFICATE NO</b>{" "}
                        </td>
                        <td className="dataDivFromSticker">
                          <b>{`${certificateNumber}${
                          settingList["amendment"] === "true" &&
                        revisionNumber > 0 ? `/AMD-${revisionNumber}` : ""
                      }`}</b>
                        </td>
                      </tr>
                      <tr>
                        <td className="stickerTd">
                          <b>INSTRUMENT</b>
                        </td>
                        <td className="dataDivFromSticker">
                          <b>{instrumentName}</b>
                        </td>
                      </tr>
                      <tr>
                        <td className="stickerTd">
                          <b>ID NO</b>
                        </td>
                        <td className="dataDivFromSticker">
                          <b>{idNumber}</b>
                        </td>
                      </tr>
                      <tr>
                        <td className="stickerTd">
                          <b>LOCATION</b>
                        </td>
                        <td className="dataDivFromSticker">
                          <b>{location}</b>
                        </td>
                      </tr>
                      <tr>
                        <td
                          className=" bottomDiv"
                          style={{ display: "flex", border: "none" }}
                          colSpan={2}
                        >
                          <div
                            style={{
                              width: "25%",
                            }}
                          >
                            <div className="bottomDivChild">
                              <b>CAL DATE</b>
                            </div>
                            <div style={{ textAlign: "center" }}>
                              <b>
                                {calDate
                                  ? moment(calDate).format("DD-MM-YYYY")
                                  : ""}
                              </b>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "25%",
                            }}
                          >
                            <div className="bottomDivChild">
                              <b>DUE DATE</b>
                            </div>
                            <div style={{ textAlign: "center" }}>
                              <b>
                                {dueDate
                                  ? moment(dueDate).format("DD-MM-YYYY")
                                  : ""}
                              </b>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "25%",
                            }}
                          >
                            <div className="bottomDivChild">
                              <b>CAL BY</b>
                            </div>
                            <div style={{ textAlign: "center" }}>
                              <b>{calibratedby ? calibratedby : ""}</b>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "25%",
                              display: "flex",
                            }}
                          >
                            <QRCode
                              value={`${DOMAIN}/#/certificate/print/${certId}`}
                              size={60}
                              align="right"
                              style={{ margin: "auto" }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Box>
          )}
        </Box>
      </Modal>
    );
  };
  const filterSection = () => (
    <div>
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ padding: "5px 0px" }}
      >
        <Grid
          item
          xs={6} sm={3} md={1} lg={1.5}
          id="certificate-certificatelistdatefrom"
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              className="Listinputcss"
              label="From"
              value={from ? new Date(from) : null}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                setFrom(newValue);
                setSearched(false);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={6} sm={3} md={1} lg={1.5}
          id="certificate-certificatelistdateto"
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              className="Listinputcss"
              label="To"
              value={to ? new Date(to) : null}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                setTo(newValue);
                setSearched(false);
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2.5}>
          <Autocomplete
            sx={{
              m: 0,
              width: "100%",
            }}
            className="Listinputcss"
            size="small"
            value={searchBy}
            id="certificate-certificatelistsearchby"
            options={
              userType == 3
                ? [
                    { key: "instrumentName", label: "Instrument Name" },
                    { key: "duc", label: "DUC ID" },
                    { key: "serialNumber", label: "Serial Number" },
                    { key: "srfNumber", label: "SRF Number" },
                    { key: "jobNumber", label: "Job Number" },
                    { key: "certificateNumber", label: "Certificate Number" },
                    { key: "locationOfInstrument", label: "DUC Location" },                  
                  ]
                : [
                    { key: "customerName", label: "Client" },
                    { key: "instrumentName", label: "Instrument Name" },
                    { key: "duc", label: "DUC ID" },
                    { key: "serialNumber", label: "Serial Number" },
                    { key: "srfNumber", label: "SRF Number" },
                    { key: "jobNumber", label: "Job Number" },
                    { key: "certificateNumber", label: "Certificate Number" },
                    { key: "locationOfInstrument", label: "DUC Location" },                  
                  { key: "ULRNo", label: "ULR No" },                  
                  ]
            }
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            isOptionEqualToValue={(option, value) => option.label === value}
            onInputChange={(event, newInputValue) => {
              if (newInputValue === "") setSearchKey("");
              setSearchBy(newInputValue);
              setSearched(false);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <TextField
            style={{ width: "100%" }}
            className="Listinputcss"
            id="certificate-certificatelistsearchbytext"
            label={"Enter " + searchBy}
            size="small"
            inputProps={{
              autoComplete: 'off',
            }}
            variant="outlined"
            value={searchKey}
            onChange={(e) => {
              setSearchKey(e.target.value);
              setSearched(false);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={1} lg={1} textAlign={"left"}>
          <Button
            id="certificate-certificatelistsearchbtn"
            variant="contained"
            size="small"
            color={searched ? "error" : "primary"}
            onClick={() => {
              searched ? clearSearch() : search();
              handleFilterClose();
            }}
          >
            {searched ? <RestartAltIcon /> : <SearchIcon />}
          </Button>
        </Grid>
        {!multiCopyURL && (
        <Grid item xs={3} sm={3} md={2} lg={2}>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setmultiPrintRow(!multiPrintRow);
            }}
          >
            Multiple PDf
          </Button>
        </Grid>
        )}
        {!multiPrintRow && userType != "3" && (
        <Grid item xs={3} sm={3} md={2} lg={1.5}>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setmultiCopyURL(!multiCopyURL);
            }}
          >
            Copy link
          </Button>
        </Grid>
        )}
      </Grid>
      {selectedRows && selectedRows.length ? (
        <Grid
          container
          spacing={2}
          align="left"
          style={{ display: "flex" }}
          sx={{ mt: 1, mb: 1 }}
        >
          {props.rolesInfo?.currentRole?.id == 2 && (
            <Grid item xs={6} sm={3} md={2} lg={2}>
              <Button
                id="certificate-certificateview_Approvebtn"
                variant="contained"
                color="success"
                size="small"
                startIcon={<CheckCircleIcon />}
                onClick={() => {
                  const selectedCertificates = certificate?.filter(cert => selectedRows.includes(cert.id)) || [];
                  const noncomplianceStatusCerts = selectedCertificates?.filter(cert => cert.complianceStatus != 1);
                  const complianceStatusCerts = selectedCertificates?.filter(cert => cert.complianceStatus == 1)?.map(cert => cert.id) || [];
                  const message = noncomplianceStatusCerts.length > 0 
                    ? `Compliance Status is failed for the following:\n${noncomplianceStatusCerts.map(cert => `Instrument Name: ${cert.instrumentName}, DUCID: ${cert.DUCID}, Job NO: ${cert.jobNumber}`).join('\n')}` 
                    : '';

                    // Concatenating compliantand non-compliant certificates' IDs
                    let allCertsToApprove = [...(noncomplianceStatusCerts?.map(cert => cert.id) || []), ...complianceStatusCerts];

                    // show alert Compliance Status failed
                    if (settingList["Compliance Status Alert"]?.toLowerCase() === "true" && noncomplianceStatusCerts.length > 0) {
                      window.alert(message);
                    }

                    if(editAccess?.includes(7) && allCertsToApprove.length > 0){
                      mulipleApproveCertificates("approve", allCertsToApprove, settingList);
                    } else{
                      if(complianceStatusCerts?.length > 0){
                        mulipleApproveCertificates("approve", complianceStatusCerts, settingList);
                      }
                    }
                }}
              >
                Approve
              </Button>
            </Grid>
          )}
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <ButtonGroup size="small" aria-label="small button group">
              <Button
                id="certificate-certificatelistmultipleqr"
                variant="contained"
              size="small"
                startIcon={<QrCode2Icon />}
                onClick={()=>{
                  sessionStorage.setItem("multipleqrIDs", selectedRows.toString())
                  navigate("/certificate/multiqr/")
                }}
              >
                Print Qr
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };
  

const handleSelectAllClick = (event) => {
  const allSelected =
    selectedRows.length ===
    certificate?.filter((row) => row.calibratedby !== null).length;

  if (!allSelected || selectedRows.length === 0) {
    const newSelectedRows = certificate
      ?.filter((row) => row.calibratedby !== null)
      ?.map((row) => row.id);
    setSelectedRows(newSelectedRows);
  } else {
    setSelectedRows([]);
  }
};

// Function to handle individual row selection
const handleRowClick = (event, id) => {
  const selectedIndex = selectedRows.indexOf(id);
  let newSelectedRows = [];

  if (selectedIndex === -1) {
    newSelectedRows = newSelectedRows.concat(selectedRows, id);
  } else if (selectedIndex === 0) {
    newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
  } else if (selectedIndex === selectedRows.length - 1) {
    newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelectedRows = newSelectedRows.concat(
      selectedRows.slice(0, selectedIndex),
      selectedRows.slice(selectedIndex + 1)
    );
  }
  setSelectedRows(newSelectedRows);
};

  return (
    <ListWrap>
      {filterModal()}
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          style={{ display: isMobile ? "block" : "none" }}
          onClick={handleFilterOpen}
        >
          <FilterAltIcon />
        </Button>
      </div>
      {/* <div
        style={{
          position: "fixed",
          top: isMobile ? "6.5%" : "10%",
          right: isMobile ? "-1%" : "0%",
          zIndex: 99,
        }}
      >
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              certificateListGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div> */}
      {!isMobile && filterSection()}
      {multiPrintRow && (
        <MultipleCertificatePrintButton
          multiPrintRow={multiPrintRow}
          setmultiPrintRow={setmultiPrintRow}
          setselectedSRfMultiple={setselectedSRfMultiple}
          clearSearch={clearSearch}
          setSearched={setSearched}
        />
      )}
      {multiCopyURL && (
        <MultipleCertificateCopyUrl
        multiCopyURL={multiCopyURL}
          setmultiCopyURL={setmultiCopyURL}
        />
      )}
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: isMobile ? "85vh" : "75vh" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            className="table-responsive"
            id="certificate-certificatelisttable"
            sx={{ borderCollapse: "collapse" }}
          >
            <TableHead>
              <TableRow>
                {userType !== "3" && !isMobile && (
                  <TableCell style={{padding:"5px"}}>
                    <Checkbox
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < certificate.length
                      }
                      checked={selectedRows.length === certificate.length}
                      onChange={handleSelectAllClick}
                    />
                  </TableCell>
                )}
                {columns.map((column) =>
                  !settingList["companyHierarchy"] &&
                  column.id === "companyHierarchy" ? null : (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth , padding : column.padding }}
                    >
                      <b>{column.label}</b>
                    </TableCell>
                  )
                )}
                <TableCell>
                  <b>Actions</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {certificate?.length > 0 ? (
                certificate.map((row, index) => {
                  let companyHierarchyCell =
                    row.companyHierarchy?.split(",") || [];
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {userType !== "3" && !isMobile ? (
                        <TableCell sx={{padding:"5px", textAlign:"center"}}>
                          
                            <FormControlLabel
                              classes={{
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              sx={{
                                gap: 2,
                                margin : 0
                              }}
                              control={
                                <Checkbox
                                  id="certificate-certificatelistselectcheckbox"
                                  // onChange={(e) => {
                                  //   updateEnableDisable(
                                  //     e.target.checked,
                                  //     row.id
                                  //   );
                                  // }}
                                  checked={selectedRows.indexOf(row.id) !== -1}
                                  onChange={(event) =>
                                    handleRowClick(event, row.id)
                                  }
                                />
                              }
                              disabled={row.calibratedby == null}
                              // label={matrixData.matrixName}
                            />

                        </TableCell>
                      ) : null}
                      <TableCell>{index + 1}</TableCell>
                      <TableCell title={row.calibrationType == 1 ? "NABL" : row.calibrationType == 2 ? "IAS" : "NONANBL"}>
                        {row.companyName}
                      </TableCell>
                      <Tooltip
                        title={
                          row?.approvedby
                            ? `Approved By ${
                                userList?.find(
                                  (user) => user.id == row.approvedby
                                )?.userName || ""
                              }`
                            : "Not Approved Yet"
                        }
                        placement="top"
                      >
                        <TableCell
                          style={{
                            backgroundColor:
                              userList?.find((user) => user.id == row.signedBy)
                                ?.userName || ""
                                ? "rgb(181 247 204)"
                                : userList?.find(
                                    (user) => user.id == row.reviewedBy
                                  )?.userName || ""
                                ? "rgb(243 146 243)"
                                : userList?.find(
                                    (user) => user.id == row.witnessBy
                                  )?.userName || ""
                                ? "#f59b79"
                                : userList?.find(
                                    (user) => user.id == row.approvedby
                                  )?.userName || ""
                                ? "rgb(160 160 247)"
                                : "defaultColor",
                          }}
                        >
                          <Link to={`/certificate/viewCertificate/${row.id}`} className="custom-link" title="View Certificate">
                          {row.serviceReqNumber}
                          </Link>
                        </TableCell>
                      </Tooltip>
                      <TableCell>
                      <Link to={`/certificate/viewCertificate/${row.id}`} className="custom-link" title="View Certificate">
                        {row.certificateNumber}
                        </Link>
                        </TableCell>
                        <TableCell>{row.ULRNo}</TableCell>
                      <Tooltip
                        title={
                          row.approvedby
                            ? `Approved By ${
                                userList.find(
                                  (user) => user.id == row.approvedby
                                )?.userName || ""
                              }`
                            : "Not Approved Yet"
                        }
                        placement="top"
                      >
                        <TableCell
                          style={{
                            backgroundColor:
                              userList?.find((user) => user.id == row.signedBy)
                                ?.userName || ""
                                ? "rgb(181 247 204)"
                                : userList?.find(
                                    (user) => user.id == row.reviewedBy
                                  )?.userName || ""
                                ? "rgb(243 146 243)"
                                : userList?.find(
                                    (user) => user.id == row.witnessBy
                                  )?.userName || ""
                                ? "#f59b79"
                                : userList?.find(
                                    (user) => user.id == row.approvedby
                                  )?.userName || ""
                                ? "rgb(160 160 247)"
                                : "defaultColor",
                          }}
                        >
                          <Link to={`/certificate/viewCertificate/${row.id}`} className="custom-link" title="View Certificate">
                          {row.jobNumber}
                          </Link>
                        </TableCell>
                      </Tooltip>
                      {settingList["companyHierarchy"] ? (
                        <>
                          {companyHierarchyCell?.length > 0 ? (
                            <>
                              <TableCell>
                                {companyHierarchyCell[0]?.trim()}
                              </TableCell>
                              {companyHierarchyCell.length > 1 ? (
                                <TableCell>
                                  {companyHierarchyCell[1]?.trim()}
                                </TableCell>
                              ) : (
                                <TableCell></TableCell>
                              )}
                            </>
                          ) : (
                            <>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </>
                          )}
                        </>
                      ) : null}
                      <TableCell>{row.instrumentName}</TableCell>
                      <TableCell>{row?.locationOfInstrument}</TableCell>
                      <TableCell>
                        {row.ranges
                          ?.replaceAll("||", ", ")
                          ?.replaceAll("|", " to ")
                          ?.replaceAll("#", "")}
                      </TableCell>
                      <TableCell>
                        {row.lc
                          ?.replaceAll("||", ", ")
                          ?.replaceAll("|", " to ")
                          ?.replaceAll("#", "")}
                      </TableCell>
                      <TableCell>{row.DUCID}</TableCell>
                      <TableCell>{row.serialNumber}</TableCell>
                      <TableCell style={{ textWrap: "nowrap"}}>
                        {moment(row.calibrationDate).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell style={{ textWrap: "nowrap"}}>
                      {row?.calFrequency == "Not Required"
                      ? "-"
                      : row.nextDueDate
                        ? moment(row.nextDueDate).format("DD-MM-YYYY")
                        : ""}
                      </TableCell>
                      {userType !== "3" && (
                        <TableCell>
                          {userList?.find((user) => user.id == row.calibratedby)
                            ?.userName || ""}
                        </TableCell>
                      )}
                      <TableCell>
                        <ButtonGroup
                          size="small"
                          aria-label="small button group"
                        >
                          <Tooltip
                            title="View Certificate"
                            placement="top-start"
                          >
                            <Button
                              id="certificate-certificatelistprint"
                              component={Link}
                              to={`/certificate/viewCertificate/${row.id}`}
                            >
                              <PreviewIcon />
                            </Button>
                          </Tooltip>
                          {userType !== "3" ? (
                            <Tooltip
                              title="Scan Certificate"
                              placement="top-start"
                            >
                              <Button
                                id="certificate-certificateliststiker"
                                onClick={(e) => {
                                  setQrCodeUrl(
                                    `${DOMAIN}/#/certificate/viewCertificate/${row.id}`
                                  );
                                  handleOpen();
                                  setInstrumentName(row.requestedname ? row.requestedname : row.instrumentName);
                                  setIdNumber(row.DUCID);
                                  setLocation(row.locationOfInstrument);
                                  setCertificateNumber(row.certificateNumber);
                                  setCalDate(row.calibrationDate);
                                  setDueDate(row.nextDueDate);
                                  getCal(row.id);
                                  setCertId(row.id);
                                  setCalType(row.calibrationType);
                                }}
                              >
                                <QrCode2Icon />
                              </Button>
                            </Tooltip>
                          ) : (
                            console.log("not a admin")
                          )}
                          <Button
                            size="small"
                            onClick={() => handleOpenProgress(row)}
                          >
                            Progress
                          </Button>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length + 1} align="center">
                   <h6><b>Data Not Found</b></h6>
                  </TableCell>
                </TableRow>
              )} 
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          id="certificate-certificatelistpagination"
          rowsPerPageOptions={[5, 10, 30, 50]}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          colSpan={3}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
        />
        <ToastContainer />
      </Box>
      {renderQrModal()}
      {renderProgressModal()}
      {errormodalIsOpen && (
        <ErrorModal
          errormodalIsOpen={errormodalIsOpen}
          closeErrorModal={closeErrorModal}
          errorMessage={errorMessage}
        />
      )}
    </ListWrap>
  );
}