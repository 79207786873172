import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, TextField, Typography } from "@mui/material";
import {MAX_COLS} from "../../../../global";

const RowDetailsModal = ({
  open,
  handleClose,
  staticTables,
  staticReadingRows,
  tableIndex,
  rowIndex,
  table,
  renderTableCell,
  tableConfig,
  isPrinting,
}) => {
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    // Only run once when the component mounts
    if (staticTables) {
      const table = staticTables[0];
      const headers = [];

      for (let i = 1; i <= MAX_COLS; i++) {
        const columnName = `c${i}`;
        const columnValue = table[columnName];

        // Check if the columnValue is not null before pushing it to the headers array
        if (columnValue !== null) {
          headers.push(columnValue);
        }
      }

      setHeaders(headers);
    }
  }, [staticTables]);

  return (
    <Modal open={open} onClose={handleClose}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "calc(100% - 30%)",
          backgroundColor: "white",
          height: "90vh",
          overflow: "scroll",
          // border: "2px solid #000",
          boxShadow: 24,
          padding: "10px",
        }}
      >
        <TableContainer component={Paper}>
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right", marginBottom: "15px" }}
            onClick={handleClose}
          >
            X
          </Button>
          <Table>
            <TableHead>
              <TableRow>
                {/* Display headers in the first column */}
                <TableCell>Headers</TableCell>
                <TableCell>Values</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {staticReadingRows?.[rowIndex]
                ?.slice(
                  2,
                  staticReadingRows?.[rowIndex]?.length -
                    (!isPrinting && tableConfig[table.id]?.isUncertainty
                      ? 2
                      : 3)
                )
                ?.map((cell, cellIndex) => {
                  return (
                    <TableRow key={cellIndex}>
                      <TableCell>{headers?.[cellIndex]}</TableCell>
                      <TableCell>
                        {renderTableCell(
                          table,
                          tableIndex,
                          staticReadingRows?.[rowIndex],
                          rowIndex,
                          cell,
                          cellIndex
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Modal>
  );
};

export default RowDetailsModal;
