var hardness = {
  g: {
      name: {
          singular: 'g',
          plural: 'g'
      },
      to_anchor: 9.80665 / 1000
  },
  'shore-A': {
      name: {
          singular: 'Shore A',
          plural: 'Shore A'
      },
      to_anchor: 0.075
  },
  'shore-D': {
      name: {
          singular: 'Shore D',
          plural: 'Shore D'
      },
      to_anchor: 0.4445
  },
  'mohs': {
      name: {
          singular: 'Mohs',
          plural: 'Mohs'
      },
      to_anchor: 1 / 10 // Assuming scale from 1 to 10
  },
  'HB': {
      name: {
          singular: 'brinell',
          plural: 'brinell'
      },
      to_anchor: 9.81 // Assuming Brinell hardness in kgf/mm² to be converted to N/mm²
  },
  'HR': {
      name: {
          singular: 'rockwell',
          plural: 'rockwell'
      },
      to_anchor: 1 // Rockwell values are dimensionless, so keeping the ratio as 1
  },
  'HV': {
      name: {
          singular: 'vickers',
          plural: 'vickers'
      },
      to_anchor: 9.81 // Assuming Vickers hardness in kgf/mm² to be converted to N/mm²
  },
  'HK': {
      name: {
          singular: 'knoop',
          plural: 'knoop'
      },
      to_anchor: 9.81 // Assuming Knoop hardness in kgf/mm² to be converted to N/mm²
  }
};

module.exports = {
  metric: hardness,
  _anchors: {
      hardness: {
          unit: 'N',
          ratio: 1
      }
  }
};
