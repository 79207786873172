import convert from "./convert-units/lib/convert_units";

function getPrecision(value) {
  const valueString = value.toString();
  const decimalIndex = valueString.indexOf(".");

  if (decimalIndex === -1) {
    return 0;
  }

  return valueString.length - decimalIndex - 1;
}

export default function unitConvertor(value, fromUnit, toUnit) {
  if (fromUnit === toUnit) return value;
  let convertedValue = value;
  try {
    convertedValue = convert(value)
      .from(fromUnit.trim())
      .to(toUnit.trim());
  } catch (e) {
    console.log(`[ERROR] Failed to convert unit (from: ${fromUnit}, to: ${toUnit}), error: `, e);
    return convertedValue;
  }

  return convertedValue;
}

export const isCovertPossible = (from, to) => {
  let possibilities;
  try {
    possibilities = convert().from(from).possibilities();
  } catch (e) {
    console.log(
      "[ERROR] Failed to check unit conversion possibility, error: ",
      e
    );
    return false;
  }
  return possibilities?.includes(to) || false;
};
